import { useState } from "react";
import { NavigationLink } from "./NavigationLink";
import { NavLink } from "react-router-dom";
import { Menu } from "lucide-react";

export const Navbar = () => {
  const [isMenu, setIsMenu] = useState(false);

  const updateIsMenu = () => {
    setIsMenu((state) => !state);
  };

  return (
    <div className="sticky   top-0  min-h-[150px] ">
      <div className="pt-4 flex items-center justify-between">
        <h3 className="mt-8 ml-3 sm:ml-3 md:ml-12 lg:ml-16 text-lg sm:text-lg md:text-xl lg:text-2xl xl:text-3xl font-serif ">
          My Gülfy <span className="text-red-500">&#10084;</span> My Lovely
          Özgür
        </h3>

        <nav className="mt-8 hidden sm:flex lg:ml-7 xl:ml-0 2xl:mr-24">
          <NavigationLink targetPage="/" content="En Güzel 2 Yılım" />
          <NavigationLink targetPage="/first" content="İlklerimiz" />
          <NavigationLink targetPage="/travel" content="Sadece Gezdik" />
        </nav>
        <Menu
          className=" w-10 h-10 text-red-500 mt-8 mr-5 sm:mr-10 sm:hidden"
          onClick={updateIsMenu}
        />
      </div>
      <hr className="mt-6 border-red-500" />
      {isMenu && (
        <>
          <nav className=" flex flex-col gap-3 ml-4 mr-4 mt-5 pb-4 sm:text-lg text-red-500  ">
            <NavLink
              className="text-center border-2 border-red-500   [&.active]:text-white [&.active]:bg-red-500"
              to="/"
              onClick={updateIsMenu}
            >
              En Güzel 2 Yılım
            </NavLink>
            <NavLink
              className="text-center border-2 border-red-500   [&.active]:text-white [&.active]:bg-red-500"
              to="/first"
              onClick={updateIsMenu}
            >
              İlklerimiz
            </NavLink>
            <NavLink
              className="text-center border-2 border-red-500   [&.active]:text-white [&.active]:bg-red-500"
              to="/travel"
              onClick={updateIsMenu}
            >
              Sadece Gezdik
            </NavLink>
          </nav>
        </>
      )}
    </div>
  );
};
