import React from "react";
import { ArrowBigLeft, ArrowBigRight, CircleX } from "lucide-react";
import { SliderText } from "./SliderText";
import { SliderHeader } from "./SliderHeader";
export const Slider = ({
  photo,
  increaseLocId,
  decreaseLocId,
  design,
  isCloseBtn = false,
  onClose,
  isText = false,
  isHeader = false,
  header = "",
}) => {
  return (
    <div className="md:flex md:gap-6">
      <div className="relative">
        {isHeader && <SliderHeader travelLoc={header} />}
        <div
          className={`${design.bg} w-[340px] h-[260px] sm:w-[600px] sm:h-[500px]  md:w-[500px] md:h-[400px] lg:w-[700px] lg:h-[550px]  xl:w-[900px] xl:h-[600px] flex justify-center items-center rounded-md border-2 border-slite-400`}
        >
          <img
            className={`max-w-[340px] h-[255px] sm:max-w-[600px] sm:h-[500px]  md:max-w-[500px] md:h-[400px] lg:max-w-[700px] lg:h-[550px] xl:max-w-[900px] xl:h-[600px]  rounded-md`}
            src={`../img/${photo.loc}`}
            alt=""
          />
        </div>

        {isCloseBtn && (
          <CircleX
            className="absolute top-2 right-2  w-6 h-6  cursor-pointer"
            onClick={onClose}
          />
        )}

        <ArrowBigLeft
          className=" absolute top-1/2 left-1 fill-black stroke-white w-8 h-8  cursor-pointer"
          onClick={decreaseLocId}
        />
        <ArrowBigRight
          className=" absolute top-1/2 right-6 sm:right-1 fill-black stroke-white  w-8 h-8 cursor-pointer"
          onClick={increaseLocId}
        />
      </div>

      {isText && <SliderText desc={photo.desc} />}
    </div>
  );
};
