import React from "react";
import { NavigationLink } from "../../components/Navbar/NavigationLink";

export const Welcome = () => {
  return (
    <div className="w-[340px] sm:w-[600px] md:w-[750px] lg:w-[1000px] xl:w-[1200px] mx-auto ">
      <h2 className=" text-center text-2xl sm:text-4xl font-semibold pt-8 sm:pt-16">
        Hoş Geldin Gülfemim
      </h2>
      <p className="mt-8 sm:mt-16 text-lg sm:text-xl">
        Bebek yüzlüm bugün seninle 2. yılımızı tamamladık. Bu 2 yılda birlikte
        birbirinden güzel, musmutlu anılar biriktirdik. İlklerimiz ve en çok
        eğlendiklerimize hep dönüp bakalım diye burayı bize hazırlıyorum. Seni
        çok seviyorum,{" "}
        <span className="text-red-500">İyi ki varız, İyi ki biz!</span>
      </p>
      <nav className="flex mt-8 sm:mt-12 justify-center items-center">
        <NavigationLink targetPage="/first" content="İlklerimiz" />
        <NavigationLink targetPage="/travel" content="Sadece Gezdik" />
      </nav>
    </div>
  );
};
