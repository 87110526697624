import React, { useState } from "react";
import { Slider } from "../../components/Slider/Slider";

export const Home = () => {
  const fotolar = [
    {
      loc: "ourFirsts/gülfy1.JPG",
      desc: {
        title: "İlk Kahvaltı Unutulmaz",
        content:
          "Henüz sevgili değildik ve geceden itibaren heyecanlandığım bir sabahtı. Tabi Kadıköy'e geldiğinde Ruşen'i de çağırdım demiştin ve bozulmuştum ama belli etmedim :) Saatlerce sohbet edip, dedikodu yapmıştık. Güzel bir günün başlangıcı.",
      },
    },
    {
      loc: "ourFirsts/gülfy2.JPG",
      desc: {
        title: "İlk Konser Çıkışı & İlk Vodafone Park",
        content:
          "Zorla kolundan tutup Murat Boz konserine götürmüştüm. Murat Boz sevdan geçicidir dedim kandım. O gün etkilendiğim en güzel an stada doğru yürürken yolda uzun süre öksürmüştüm ve ben iyi olana kadar ilgilenmiştin. Büyük takımın büyük taraftarı seni çok seviyorum.",
      },
    },
    {
      loc: "ourFirsts/gülfy3.jpg",
      desc: {
        title: "Tavlada Masterlaşmaya Giden Yolculuğun İlk Adımı",
        content:
          "Sana tavla öğreteyim dediğimde bana kimse öğretemedi demiştin. Ben öğretirim rahat ol demiştim, o günden tavla potansiyelin belliydi. 24 Şubat 2023'te ilk defa beni mars ettiğinde gurur duymuştum.",
      },
    },
    {
      loc: "ourFirsts/gülfy4.JPG",
      desc: {
        title: "Gülfym'in İlk Etkinliği ve Küçük Bir Ayna Molası",
        content:
          "Bebeğimin ilk etkinliği. Sabah geldiğimde GKM'nin önünde heyecanlı heyecanlı bekliyordun. Tüm etkinlik peşinde koşturup yardım etmiştim. Çok soğukkanlı kalıp etkinliği yönetmiştin. Etkinlik sonundaki mutluluğun hala aklımda.",
      },
    },
    {
      loc: "ourFirsts/gülfy5.jpg",
      desc: {
        title: "1 Ay Sonra Masada 2 Tatlı",
        content:
          "1 ay boyunca vaveyla yeme planı yapmıştık. Sonunda studyde bunalıp gitmiştik. O gün hatırladığım masadaki tek tatlı sendin.",
      },
    },
    {
      loc: "ourFirsts/gülfy6.JPG",
      desc: {
        title: "Canım ve Apartmanım",
        content:
          "Sen böyle derdin 'Canım ve Apartmanım'. O gün yaşadıklarımızı sen daha iyi hatırlarsın :) Canım'ı özledik be Gülfy.",
      },
    },
    {
      loc: "ourFirsts/gülfy7.JPG",
      desc: {
        title: "3+1 Evdeki Bıcır Manit",
        content:
          "Evin neşesi bıcır manitim benim. Annemin gönderdiği köfteleri yakmamaya çalışırken sen... ",
      },
    },
    {
      loc: "ourFirsts/gülfy8.jpg",
      desc: {
        title: "Şoförlüğü bir markadır",
        content:
          "Kendisi ilişkinin şoförü olur. Sen arabayı sür, şarkılar bana emanet Gülfemim. İlk zamanlar sürekli kornaya basıp kızdırıyordun beni. Bunu da aştığımıza göre uzun yol yapma vaktimiz geldi.",
      },
    },
    {
      loc: "ourFirsts/gülfy9.jpg",
      desc: {
        title:
          "Kadıköy'de İlk Ders Çalışma Denemelerimiz (Bol Sohbet, Cüzi Miktarda Çalışma)",
        content:
          "Çarşamba günleri Kadıköy'de ders çalışma eventlerimizin ilklerinden. Her hafta farklı cafeler keşfettiğimiz zamanlar. Yan yana kimya ve matematik çalışırken birbirimize dönüp sıkılmış bakışları attıktan sonra sarılıp devam ediyorduk. ",
      },
    },
    {
      loc: "ourFirsts/gülfy10.JPG",
      desc: {
        title: "Fala İnanma, Gülfy'siz Kalma",
        content:
          "Falcı Bacı Gülfy Fenerbahçe'nin şampiyonluk ihtimalini yorumluyor. Karşılıklı fal bakıp güldüğümüz soğuk bir kahve günü.",
      },
    },
    {
      loc: "ourFirsts/gülfy11.JPG",
      desc: {
        title:
          "Bi' şeyler yapabilirsem güzel gözlerin için Başından geçeni anlat, masaldır benim için ",
        content:
          "İlk konserimiz. Beşiktaş'ta bira içip mekana geçiş. Öne ilerlemek için uğraşlar. Her şarkıda sarılarak dans ettik. Çok güzeldin kadınım.",
      },
    },
    {
      loc: "ourFirsts/gülfy12.jpg",
      desc: {
        title: "Sevdiğimiz IKEA Gezilerinin İlki",
        content:
          "Bebeğimle 6 ayda bir yapılan mistik IKEA gezileri. Sana küçük köpek sevgisi kazandırmaya çalıştığım vakitler.",
      },
    },
    {
      loc: "ourFirsts/gülfy13.JPG",
      desc: {
        title: "Kasa Önü Şımarıklığı",
        content: "Yılbaşı süsleri görmeye dayanamayan biz.",
      },
    },
    {
      loc: "ourFirsts/gülfy14.jpg",
      desc: {
        title: "Gülfy'den Cadde101 Dersi",
        content:
          "5 yıl Kadıköy'de okusam da senden cadde hakkında öğrenecek çok şey var. Kahve içip saatlerce bir sağa bir sola yürüdüğümüz cadde günlükleri.",
      },
    },
    {
      loc: "ourFirsts/gülfy15.JPG",
      desc: {
        title: "Güzel Her Işıkta Fotoğraf Çekilmeliyiz",
        content:
          "Gördüğümüz her yılbaşı süslemesinin altında fotoğraf çekildiğimiz gün. Caddede koştura koştura fotoğraf çekilmeye gidiyordun.",
      },
    },
    {
      loc: "ourFirsts/gülfy16.jpg",
      desc: {
        title: "Yeni Yıl Gecesi ve İyi ki Biz",
        content:
          "Her yıla böyle neşeli girelim bebeğim. 00.00'da bebeğimi kucakladığım yeni yıl gecelerimiz olsun.",
      },
    },
    {
      loc: "ourFirsts/gülfy17.JPG",
      desc: {
        title: "İlk Mantı Günü ve Sınav Öncesi İlk Sabahlama Girişimi",
        content:
          "İlk başarısız sınava sabahlama girişimimizdi. Mantı biraz ağır geldi gibi. Gece 2'ye kadar çalışıp yorgun Gülfy ve kalk kalk diyen ben. Sonunda uyumuştuk ama en azından denedik.",
      },
    },
    {
      loc: "ourFirsts/gülfy18.JPG",
      desc: {
        title: "Güzel Gülüşlüm (Muhtemelen Cadde)",
        content: "Hava soğuk, yanımda güzel gülüşüyle my gülfym.",
      },
    },
    {
      loc: "ourFirsts/gülfy19.jpg",
      desc: {
        title: "Baş başa ilk rakımız - 14.02.2023",
        content:
          "İlk sevgililer günümüz ve ilk rakımız. Boşver birayı, bizim senle oturup rakı içmemiz lazım kadınım.",
      },
    },
    {
      loc: "ourFirsts/gülfy20.JPG",
      desc: {
        title: "Baş başa ilk rakımız - 14.02.2023",
        content:
          "Saatlerce boş yapıp, yurt dışı anılarımızı konuştuğumuz bir rakı mesaisi. ",
      },
    },
    {
      loc: "ourFirsts/gülfy21.jpg",
      desc: {
        title: "Akvaryum ve Gergin Gülfy",
        content:
          "En keyifli eventlerimizden birisiydi. Her girdiğimiz bölümde korkup sarılıyordun :) Penguen Gülfy > Balık Gülfy",
      },
    },
    {
      loc: "ourFirsts/gülfy22.jpg",
      desc: {
        title: "Hanımcılık kazandı",
        content:
          "Minik kartalımla bir günlüğüne kartal olduğumuz bir gün. Stat rehberinden daha iyi stadı anlatmıştın.",
      },
    },
    {
      loc: "ourFirsts/gülfy23.JPG",
      desc: {
        title: "1. yılımız ve galata",
        content:
          "Ve en büyük iyi kimle 1. yıl dönümümüz. Gelecek hakkında konuştuğumuz, planlar yaptığımız bir akşam. Galata'nın altında çok güzeldik bebeğim.",
      },
    },
    {
      loc: "ourFirsts/gülfy24.JPG",
      desc: {
        title: "Burak Bora Turumuz",
        content:
          "Mantı gününde liseni gezdirmiştin. Küçük Gülfy'i yakından tanıdığım bir gündü. Dışarda voleybol oynar. İç kısımda kaloriferin olduğu yerde oturur, dışarıyı izler. Umarım Kadıköy Anadolu'yu da gezebileceğiz :)",
      },
    },
    {
      loc: "ourFirsts/gülfy25.jpg",
      desc: {
        title: "Araba sorun değil Gülfymi kaybedemem",
        content:
          "AVM otoparkında arabayı kaybetmeyelim bahanesiyle güzelimin fotoğraflarını çekerken biz.",
      },
    },
    {
      loc: "ourFirsts/gülfy26.jpg",
      desc: {
        title:
          "Kimse anlamaz bende aşkını, Sen bir çocuk, gülen yerinde durmayan",
        content:
          "İlk basket maçımız. Sen ve Fenerbahçe. Hanginizi izlesem bilemediğim bir gündü. Güzel suratlım aşığım sana.",
      },
    },
  ];

  const increaseLocId = () => {
    if (locId !== fotolar.length - 1) setLocId((prev) => prev + 1);
  };

  const decreaseLocId = () => {
    if (locId > 0) setLocId((prev) => prev - 1);
  };

  const [locId, setLocId] = useState(0);

  return (
    <div className="mt-4 sm:mt-12 w-[340px] sm:w-[600px] md:w-[750px] lg:w-[1000px] xl:w-[1200px] flex flex-col justify-center mx-auto gap-3 ">
      <h3 className=" mx-auto text-2xl sm:text-3xl font-semibold">
        2 Yılımız Nasıl Geçti?
      </h3>
      <div className="mt-6 sm:mt-12">
        <Slider
          photo={fotolar[locId]}
          increaseLocId={increaseLocId}
          decreaseLocId={decreaseLocId}
          design={{
            bg: "bg-black",
            width: "900px",
            height: "600px",
          }}
          isText={true}
        />
      </div>
    </div>
  );
};
