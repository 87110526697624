import { createBrowserRouter, RouterProvider } from "react-router-dom";

// layouts
import { MainLayout } from "./layouts/MainLayout";

// pages
import { Main } from "./pages/MainPage/Main";
import { Home } from "./pages/HomePage/Home";
import { Travel } from "./pages/TravelPage/Travel";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          path: "/",
          element: <Main />,
        },
        {
          path: "first",
          element: <Home />,
        },
        {
          path: "travel",
          element: <Travel />,
        },
      ],
    },
    {
      path: "*",
      element: <Home />,
    },
  ]);

  return <RouterProvider router={router}></RouterProvider>;
}

export default App;
