import React from "react";

export const MovingImg = () => {
  const photoToRight = [
    {
      loc: "best/best1.jpg",
    },
    {
      loc: "best/best2.JPG",
    },
    {
      loc: "best/best3.jpg",
    },
    {
      loc: "best/best4.JPG",
    },
    {
      loc: "best/best5.jpg",
    },
    {
      loc: "best/best6.jpg",
    },
    {
      loc: "best/best7.jpg",
    },
    {
      loc: "best/best8.jpg",
    },
  ];

  const photoToLeft = [
    {
      loc: "best/best9.jpg",
    },
    {
      loc: "best/best10.jpg",
    },
    {
      loc: "best/best11.JPG",
    },
    {
      loc: "best/best12.JPG",
    },
    {
      loc: "best/best13.JPG",
    },
    {
      loc: "best/best14.JPG",
    },
    {
      loc: "best/best15.JPG",
    },
    {
      loc: "best/best16.jpg",
    },
  ];

  return (
    <>
      <div className="w-full inline-flex flex-nowrap overflow-hidden mt-12 sm:mt-36 [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-200px),transparent_100%)]">
        <ul className="flex items-center justify-center md:justify-start [&_li]:mx-3 sm:[&_li]:mx-8 [&_img]:max-w-none animate-infinite-right-scroll">
          {photoToRight.map((photo, index) => (
            <li key={index}>
              <img
                src={`./img/${photo.loc}`}
                alt="Facebook"
                className="w-36 h-36 sm:w-64 sm:h-64"
              />
            </li>
          ))}
        </ul>
        <ul
          className="flex items-center justify-center md:justify-start [&_li]:mx-3 sm:[&_li]:mx-8 [&_img]:max-w-none animate-infinite-right-scroll"
          aria-hidden="true"
        >
          {photoToRight.map((photo, index) => (
            <li key={index}>
              <img
                src={`./img/${photo.loc}`}
                alt="Facebook"
                className="w-36 h-36 sm:w-64 sm:h-64"
              />
            </li>
          ))}
        </ul>
      </div>
      <div className="w-full inline-flex flex-nowrap overflow-hidden mt-4 [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-200px),transparent_100%)]">
        <ul className="flex items-center justify-center md:justify-start [&_li]:mx-3 sm:[&_li]:mx-8 [&_img]:max-w-none animate-infinite-left-scroll">
          {photoToLeft.map((photo, index) => (
            <li key={index}>
              <img
                src={`./img/${photo.loc}`}
                alt="Facebook"
                className="w-36 h-36 sm:w-64 sm:h-64"
              />
            </li>
          ))}
        </ul>
        <ul
          className="flex items-center justify-center md:justify-start [&_li]:mx-3 sm:[&_li]:mx-8 [&_img]:max-w-none animate-infinite-left-scroll"
          aria-hidden="true"
        >
          {photoToLeft.map((photo, index) => (
            <li key={index}>
              <img
                src={`./img/${photo.loc}`}
                alt="Facebook"
                className="w-36 h-36 sm:w-64 sm:h-64"
              />
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};
