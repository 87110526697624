import React from "react";
import { NavLink } from "react-router-dom";

export const NavigationLink = ({
  targetPage,
  content,
  design = { bg: "bg-white", color: "text-red-500" },
}) => {
  return (
    <>
      <NavLink
        className={`flex justify-center items-center sm:w-32 sm:h-12 md:w-36 md:h-12 lg:w-44 lg:h-12 border-2 border-red-500 mr-2 p-1 sm:p-0 sm:mr-2 lg:mr-3  xl:mr-8 sm:text-md xl:text-xl  ${design.bg} ${design.color} hover:bg-red-400 hover:text-white  [&.active]:text-white [&.active]:bg-red-500  `}
        to={targetPage}
      >
        {content}
      </NavLink>
    </>
  );
};
