import React from "react";
import { MovingImg } from "../../components/MovingImages/MovingImg";
import { Welcome } from "./Welcome";

export const Main = () => {
  return (
    <div>
      <Welcome />
      <MovingImg />
    </div>
  );
};
