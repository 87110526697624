import React from "react";

export const SliderHeader = ({ travelLoc }) => {
  return (
    <>
      <div className=" h-10 flex justify-center items-center text-2xl bg-slate-200 text-black  rounded-md">
        {travelLoc}
      </div>
    </>
  );
};
