import React from "react";

export const SliderText = ({ desc }) => {
  return (
    <div>
      <h3 className="text-center md:text-start mt-4 md:mt-0 sm:text-xl lg:text-2xl font-semibold text-red-500">
        {" "}
        {desc.title}{" "}
      </h3>
      <p className="mt-3 md:mt-6 text-md sm:text-lg"> {desc.content} </p>
    </div>
  );
};
