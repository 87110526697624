import { Outlet } from "react-router-dom";
import { Navbar } from "../components/Navbar/Navbar";

export const MainLayout = () => {
  return (
    <div className="app ">
      <div>
        <Navbar />
      </div>
      <main>
        <Outlet />
      </main>
    </div>
  );
};
